import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";

interface Props {
    showDialog: boolean,
    nonExistedNeighborhoodIds: string[],
    onCloseDialog: () => void
}

export const RoomManagerUploadInconsistentDialog: React.FC<Props> = (props) => {
    const {showDialog, nonExistedNeighborhoodIds, onCloseDialog} = props;
    const {t} = useTranslation();

    console.log(`UploadInconsistent ${JSON.stringify(nonExistedNeighborhoodIds)}`)

    return (
        <Dialog open={showDialog}>
            <DialogContent>
                <h3>{t("rm_uploadInconsistentDialog-content")}</h3>
                {nonExistedNeighborhoodIds.length > 0 && nonExistedNeighborhoodIds.map(x => (<p>{x}</p>))}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCloseDialog}
                    color={"primary"}
                    variant={"contained"}>
                    {t("rm_uploadInconsistentDialog-close-button")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}