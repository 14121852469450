import React, {CSSProperties, useEffect, useRef, useState} from "react";
import {useRoomPlanContext} from "../../hooks/useRoomPlanContext";
import {List, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import "../../styles/styleRoomPlan.css"
import {MeetingRoom} from "../../API";
import Divider from "@material-ui/core/Divider";
import {Inventory} from "@mui/icons-material";
import {itemToDisplay} from "../../types/RoomPlanContextTypes";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";


interface Props {
    mousePosX: number,
    mousePosY: number,
    offset: number,
    styleCell: CSSProperties
}

const RoomPlanHoverDialog: React.FC<Props> = (props) => {
    const {
        mousePosX,
        mousePosY,
        offset,
        styleCell
    } = props

    const {
        itemsToDisplay,
        room
    } = useRoomPlanContext()
    const {
        meetingRoomService: {meetingRoomMap},
        buildingEquipmentService: {buildingEquipmentMap}
    } = useMainApplicationContext()
    const [elementPositionX, setElementPositionX] = useState(mousePosX);
    const [elementPositionY, setElementPositionY] = useState(mousePosY);
    const elementRef = useRef<HTMLDivElement>(null);
    const isNotHint = itemsToDisplay.length > 0 && (itemsToDisplay[0].isHint === undefined || itemsToDisplay[0].isHint === false);
    useEffect(() => {
        if (elementRef.current) {
            if (window.innerWidth - offset < (mousePosX + elementRef.current.offsetWidth)) {
                setElementPositionX(window.innerWidth - elementRef.current.offsetWidth - offset)
            }
            if (window.innerHeight - offset < (mousePosY + elementRef.current.offsetHeight)) {
                setElementPositionY(window.innerHeight - elementRef.current.offsetHeight - offset)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function renderEquipment(item: itemToDisplay, meetingRoom: MeetingRoom) {
        const occurrencesOfEquipment = new Map<string, number>()
        fillOccurrences();

        function fillOccurrences() {
            meetingRoom.equipmentIds?.forEach(id => {
                if (occurrencesOfEquipment.has(id)) occurrencesOfEquipment.set(id, occurrencesOfEquipment.get(id)! + 1)
                else occurrencesOfEquipment.set(id, 1)
            })
        }

        let equipmentToDisplay = meetingRoom.equipmentIds?.map(id => buildingEquipmentMap.get(id)!) ?? [];
        equipmentToDisplay = equipmentToDisplay.filter((element, index) => (equipmentToDisplay.indexOf(element) === index) && element)
        equipmentToDisplay.sort((e1,e2)=> e1.name.localeCompare(e2.name))
        return (
            <ListItem
                key={item.id + "equipmentItem"}
                sx={{
                    paddingTop: 0.1,
                    paddingBottom: 0.1,
                    paddingLeft: (theme) => theme.spacing(1)
                }}>
                <ListItemIcon key={item.id + "equipmentItemIcon"} sx={{minWidth: (theme) => theme.spacing(4)}}>
                    <Inventory/>
                </ListItemIcon>
                <ListItemText key={item.id + "equipmentItemText"}>
                    {equipmentToDisplay.map((item, index) => {
                        return (
                            <div key={item?.equipmentId + "_tooltip"}>
                                <span>
                                    {`${(occurrencesOfEquipment.get(item.equipmentId) ?? 0) > 1 ?
                                        "" + occurrencesOfEquipment.get(item.equipmentId) + "x " : ""}${item?.name}`}
                                </span>
                                <br/>
                            </div>)
                    })}</ListItemText>
            </ListItem>
        )
    }

    return (
        <Paper ref={elementRef} elevation={3} sx={{
            overflowWrap: 'nowhere',
            position: "absolute",
            left: elementPositionX,
            top: elementPositionY,
            backgroundColor: "white",
            pointerEvents: "none",
            whiteSpace: isNotHint ? "nowrap" : "normal"
        }}>
            <List sx={{width: '100%', minWidth: '100'}} disablePadding={false} dense={true}>
                {itemsToDisplay.length > 0 && itemsToDisplay.map((itemToDisplay, index) => {
                    let meetingRoom: MeetingRoom | undefined = undefined
                    let shouldDisplayEquipment = itemToDisplay.isMeetingRoom;
                    if (shouldDisplayEquipment) {
                        meetingRoom = meetingRoomMap.get(itemToDisplay.id.slice(5) + "_" + room.roomId)!
                        if (meetingRoom?.equipmentIds)
                            shouldDisplayEquipment = meetingRoom.equipmentIds.length > 0;
                        else
                            shouldDisplayEquipment = false
                    }
                    const shouldDisplayHint = itemToDisplay.text.length > 0
                    return (
                        <div key={itemToDisplay.id ?? "" + index + "hintParent"}>
                            {shouldDisplayHint && <ListItem
                                key={index}
                                sx={{
                                    paddingTop: 0.1,
                                    paddingBottom: 0.1,
                                    paddingLeft: (theme) => theme.spacing(1)
                                }}>
                                <ListItemIcon key={index + "icon"} sx={{minWidth: (theme) => theme.spacing(4)}}>
                                    {itemToDisplay.icon}
                                </ListItemIcon>
                                <ListItemText key={index + "text"}
                                              primary={itemToDisplay.text}/>
                            </ListItem>}
                            {shouldDisplayHint && shouldDisplayEquipment &&
                                <Divider key={itemToDisplay.id + "divider"}/>}
                            {shouldDisplayEquipment && renderEquipment(itemToDisplay, meetingRoom!)}
                        </div>
                    )
                })
                }
            </List>
        </Paper>

    );
}

export default RoomPlanHoverDialog;
