import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Divider from '@material-ui/core/Divider';
import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import CreateRoomComponent from "../CreateRoomComponent";
import {
    Booking,
    Building,
    CreateMeetingRoomInput,
    DeleteNeighborhoodInput,
    MeetingRoom,
    MeetingRoomBooking,
    Neighborhood,
    NeighborhoodInput,
    Room, RoomPlanUploadActionsInput,
    SeatConfig
} from "../../API";
import {
    getElementsToAddAndDelete,
    getMeetingRoomIdsToAddAndDelete,
    getSeatsToAddAndDelete,
    getUniqueBuildings, getAllAssignedNonExistingNeighborhoods,
    isSvgFileValid, MeetingRoomAndNeighborhood,
    reformatNeighborhoodId, SeatAndNeighborhood
} from "../../Utils/Helpers";
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {
    createHint,
    createMeetingRooms,
    createSecureNeighborhoods,
    createSecureSeatConfig,
    deleteHint,
    deleteSecureMeetingRoom,
    deleteSecureMeetingRoomBooking,
    deleteSecureNeighborhoods,
    deleteSecureRoom,
    deleteSecureSeatBooking,
    deleteSecureSeatConfig,
    publishRoomAccessChange as publishRoomAccessChangeTemplate,
    updateSeatConfig,
    updateSecureMeetingRoom,
    updateSecureRoom,
    updateSecureSeatBooking,
    roomPlanSecureUploadActions
} from "../../graphql/mutations";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    getMeetingRoomBookings,
    getMeetingRooms,
    getNeighborhoodsByRoomId,
    getPresignedRoomplanUrl,
    getSeatBookings,
    getSeatConfigs
} from "../../graphql/queries";
import SeatManagerComponent from "../SeatManagerComponent";
import MeetingRoomManagerComponent from "../MeetingRoomManagerComponent";
import {useCognitoUserList} from "../../hooks/useCognitoUserList";
import RoomManagerTableComponent from "./RoomManagerTableComponent";
import RoomManagerUploadConfirmationDialog from "./RoomManagerUploadConfirmationDialog";
import RoomManagerUploadSvgButton from "./RoomManagerUploadSvgButton";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {useErrorContext} from "../../hooks/useErrorContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {useTranslation} from "react-i18next";
import NeighborhoodManagerComponent from "../NeighborhoodManagerComponent";
import Sit2GetherAddIcon from "../../styles/Sit2GetherAddIcon";
import RoomHintsManagerComponent from "./RoomHintsManagerComponent";
import {MeetingRoomType} from "../../Utils/Enums";
import {SelectItem} from "../../Utils/MeetingRoomBookingSelectorsUtil";
import GenericSelectorComponent from "../SelectorComponents/GenericSelectorComponent";
import {useNeighborhoodIdValidation} from "../../hooks/useNeighborhoodIdValidation";
import InfoDialog from "../InfoDialog";
import {usePermissionHelper} from "../../hooks/usePermissionHelper";
import {useRoomPlanFromS3} from "../../hooks/useRoomPlanFromS3";
import {RoomManagerUploadInconsistentDialog} from "./RoomManagerUploadInconsistentDialog";

dayjs.extend(utc)

export interface RoomManagerProps {
    showRoomManager: boolean
    setShowRoomManager: (value: boolean) => void

}



function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const initialAlertStates = {success: false, error: false, error_create_room: false}

const HANDLEE_UPDATE_ROOM_IS_BOOKING_DISABLED = "updateRoomIsBookingDisabled";
const HANDLE_UPDATE_ROOM_IS_TIME_ACTIVE = "updateRoomIsTimeActive";
const HANDLE_UPDATE_ROOM_PLAN = "updateRoomPlan";


const RoomManagerComponent: React.FC<RoomManagerProps> = (props) => {
    const MAX_NEIGHBORHOOD_ID_LENGTH = 50;
    const {
        neighborhoodWithTooLongId,
        isNeighborhoodIdMaxLengthDialogOpen,
        setIsNeighborhoodIdMaxLengthDialogOpen,
        areNeighborhoodIdsValid
    } = useNeighborhoodIdValidation(MAX_NEIGHBORHOOD_ID_LENGTH);
    const {showRoomManager, setShowRoomManager} = props
    const {
        currentUser,
        rooms,
        buildingList,
        managedBuildings,
        meetingRoomService: {meetingRoomMap}
    } = useMainApplicationContext()
    const {reportError} = useErrorContext();
    const cognitoUserList = useCognitoUserList(currentUser)

    const permissionHelper = usePermissionHelper();
    const buildingsWithManagedNeighborhood = buildingList.filter(building => permissionHelper.hasManagedNeighborhoodInBuilding(building)) || [];
    const allowedBuildingsWithDuplicates = managedBuildings.concat(buildingsWithManagedNeighborhood);
    const allowedBuildings = getUniqueBuildings(allowedBuildingsWithDuplicates);

    const [roomPlanSvgFileToUpload, setRoomPlanSvgFileToUpload] = useState<File>()
    const [roomToBeUpdatedAccordingToTimeFeature, setRoomToBeUpdatedAccordingToTimeFeature] = useState<Room>();
    const [multipleBookingsPerSeat, setMultipleBookingsPerSeat] = useState<Booking[]>([])
    const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(undefined);
    const currentRoomPlan = useRoomPlanFromS3(selectedRoom)

    const [showCreateRoom, setShowCreateRoom] = useState(false)
    const [isShowAlerts, setIsShowAlerts] = useState(initialAlertStates);
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
    const [onDeleteLoading, setOnDeleteLoading] = useState(false)
    const [onUploadLoading, setOnUploadLoading] = useState(false)
    const [showSeatManagerDialog, setShowSeatManagerDialog] = useState(false)
    const [showNeighborhoodManagerDialog, setShowNeighborhoodDialog] = useState(false)
    const [showRoomHintsConfig, setShowRoomHintsConfig] = useState(false)
    const [showMeetingRoomManagerDialog, setShowMeetingRoomManagerDialog] = useState(false)
    const [showUploadConfirmationDialog, setShowUploadConfirmationDialog] = useState(false)
    const [showUploadInconsistentDialog, setShowUploadInconsistentDialog] = useState(false)
    const [nonExistingNeighborhoodIds, setNonExistingNeighborhoodIds] = useState<string[]>([])

    const [isLoadingBookingsToDelete, setIsLoadingBookingsToDelete] = useState<boolean>(false)
    const [isShowingDisableTimeBookingConfirmationDialog, setIsShowingDisableTimeBookingConfirmationDialog] = useState(false);

    const [seatIdsToAdd, setSeatIdsToAdd] = useState<SeatAndNeighborhood[]>([]);
    const [seatIdsToDelete, setSeatIdsToDelete] = useState<SeatAndNeighborhood[]>([]);
    const [seatsToUpdate, setSeatsToUpdate] = useState<SeatAndNeighborhood[]>([]);

    const [hintIdsToAdd, setHintIdsToAdd] = useState<string[]>([]);
    const [hintIdsToDelete, setHintIdsToDelete] = useState<string[]>([]);

    const [neighborhoodIdsToAdd, setNeighborhoodIdsToAdd] = useState<string[]>([]);
    const [neighborhoodIdsToDelete, setNeighborhoodIdsToDelete] = useState<string[]>([]);

    const [meetingRoomsToAdd, setMeetingRoomsToAdd] = useState<MeetingRoom[]>([]);
    const [meetingRoomIdsToDelete, setMeetingRoomIdsToDelete] = useState<string[]>([]);
    const [meetingRoomsToUpdate, setMeetingRoomsToUpdate] = useState<MeetingRoom[]>([]);

    const [newRoomPlanHasHints, setNewRoomPlanHasHints] = useState<boolean>(false);
    const [newRoomPlanHasNeighborhood, setNewRoomPlanHasNeighborhood] = useState<boolean>(false);
    const [newRoomPlanHasMeetingRooms, setNewRoomPlanHasMeetingRooms] = useState<boolean>(false);

    const [selectedBuilding, setSelectedBuilding] = useState<Building>(allowedBuildings[0]);

    const [isSVGUploadAbortedDialogOpen, setIsSVGUploadAbortedDialogOpen] = useState(false);

    useEffect(() => {
        if (!selectedBuilding) {
            setSelectedBuilding(allowedBuildings[0])
        }
    }, [allowedBuildings]);

    // Helper state variable to fire upload of room plan when changed
    const [roomPlanUploadCount, setRoomPlanUploadCount] = useState<number>(0)

    const {refetch: refetchNeighborhoods} = useQuery(gql(getNeighborhoodsByRoomId), {
        skip: !selectedRoom || !selectedRoom.roomId
    });
    const [updateRoomMutation] = useMutation(gql(updateSecureRoom))
    const [deleteRoomMutation] = useMutation(gql(deleteSecureRoom))
    const [deleteBookingMutation] = useMutation(gql(deleteSecureSeatBooking))
    const [updateBookingMutation] = useMutation(gql(updateSecureSeatBooking))
    const [publishRoomAccessChange] = useMutation(gql(publishRoomAccessChangeTemplate))
    const [createSeatConfigMutation] = useMutation(gql(createSecureSeatConfig))
    const [deleteSeatConfigMutation] = useMutation(gql(deleteSecureSeatConfig))
    const [updateSeatConfigMutation] = useMutation(gql(updateSeatConfig))
    const {refetch: refetchSeatConfigs} = useQuery(gql(getSeatConfigs), {
        skip: !selectedRoom || !selectedRoom.roomId
    })
    const [createHintMutation] = useMutation(gql(createHint), {
        context: {headers: {authorization: "Auth " + currentUser.jwt}}
    })
    const [deleteHintMutation] = useMutation(gql(deleteHint), {
        context: {headers: {authorization: "Auth " + currentUser.jwt}}
    })
    const [createNeighborhoodsMutation] = useMutation(gql(createSecureNeighborhoods))
    const [deleteNeighborhoodsMutation] = useMutation(gql(deleteSecureNeighborhoods))
    const [createMeetingRoomsMutation] = useMutation(gql(createMeetingRooms))
    const [deleteMeetingRoomMutation] = useMutation(gql(deleteSecureMeetingRoom))
    const [updateMeetingRoomMutation] = useMutation(gql(updateSecureMeetingRoom))
    const [deleteMeetingRoomBookingMutation] = useMutation(gql(deleteSecureMeetingRoomBooking))
    const {refetch: refetchMeetingRooms} = useQuery(gql(getMeetingRooms), {
        skip: !selectedRoom || !selectedRoom.roomId
    })
    const {refetch: refetchBookingsByRoom} = useQuery(gql(getSeatBookings), {
        skip: !selectedRoom || !selectedRoom.roomId
    })
    const {refetch: refetchMeetRoomBookingByRoom} = useQuery(gql(getMeetingRoomBookings), {
        skip: !selectedRoom || !selectedRoom.roomId
    })
    const {refetch: refetchPreSignedUrl} = useQuery(gql(getPresignedRoomplanUrl));
    const {refetch: getBookingsWhenTimeIsActive} = useQuery(gql(getSeatBookings));//TODO: useBookingList
    const {refetch: getBookingsOfRoomPlan} = useQuery(gql(getSeatBookings), {
        skip: !roomToBeUpdatedAccordingToTimeFeature
    })
    const [roomPlanSecureUploadActionsMutation] = useMutation(gql(roomPlanSecureUploadActions))
    const bookingsByRoom = 'bookingsByRoom';

    const triggerUploadRoomPlan = () => {
        setRoomPlanUploadCount(roomPlanUploadCount + 1)
    }

    const deleteStartIcon = onDeleteLoading ? <CircularProgress style={{color: "white"}} size={25}/> : <DeleteIcon/>
    const uploadStartIcon = onUploadLoading ? <CircularProgress style={{color: "white"}} size={25}/> :
        <CloudUploadIcon/>

    const getNeighborHoodIdsFromRoomPlan = (fileAsString: string): string[] => {
        if (fileAsString === "") {
            return []
        }
        const roomPlanParser = new DOMParser();
        const roomPlanDoc = roomPlanParser.parseFromString(fileAsString, "image/svg+xml");
        const neighborhoods = roomPlanDoc.getElementById("neighborhoods")
        return neighborhoods ? getNeighborhoodIds(neighborhoods) : []

        function getNeighborhoodIds(neighborhoodGroup: HTMLElement): string[] {
            let ids: string[] = []
            const groupElements = Array.from(neighborhoodGroup.getElementsByTagName("rect"));
            groupElements.forEach((element: SVGGElement) => {
                let id = element.getAttribute("id");
                if (id !== null && id !== "") {
                    ids.push(id)
                }
            })
            return ids
        }

    }

    const getSeatAndNeighborhoodIdsFromPlan = (fileAsString: string): SeatAndNeighborhood[] => {
        if (fileAsString === "") {
            return []
        }
        const roomPlanParser = new DOMParser();
        const roomPlanDoc = roomPlanParser.parseFromString(fileAsString, "image/svg+xml");
        const seatGroup = roomPlanDoc.getElementById("seats")
        if (seatGroup === null) {
            throw new Error("No group with id seats")
        }

        return getSeatAndNeighborhoodIds(seatGroup)

        function getSeatAndNeighborhoodIds(seatGroup: HTMLElement): SeatAndNeighborhood[] {
            let seats: SeatAndNeighborhood[] = []
            const groupElements = Array.from(seatGroup.getElementsByTagName("g"));
            groupElements.forEach((element: SVGGElement) => {
                let seatId = element.getAttribute("id");
                let neighborhoodId = element.getAttribute("neighborhoodId") ?? "";
                if (seatId !== null && seatId !== "") {
                    const seatAndNeighborhoodId: SeatAndNeighborhood = {
                        seatId: seatId,
                        neighborhoodId: neighborhoodId
                    }
                    seats.push(seatAndNeighborhoodId)
                }
            })
            return seats
        }
    }

    const getMeetingRoomsAndNeighborhoodIdsFromPlan = (fileAsString: string): MeetingRoomAndNeighborhood[] => {
        if (fileAsString === "") {
            return []
        }
        const roomPlanParser = new DOMParser();
        const roomPlanDoc = roomPlanParser.parseFromString(fileAsString, "image/svg+xml");
        const meetingRoomGroup = roomPlanDoc.getElementById("meetingRooms")
        if (meetingRoomGroup === null) {
            throw new Error("No group with id meeting rooms")
        }

        return getMeetingRoomAndNeighborhoodIds(meetingRoomGroup)

        function getMeetingRoomAndNeighborhoodIds(meetingRoomGroup: HTMLElement): MeetingRoomAndNeighborhood[] {
            let meetingRoomsAndNeighborhood: MeetingRoomAndNeighborhood[] = []
            const groupElements = Array.from(meetingRoomGroup.getElementsByTagName("g"));
            groupElements.forEach((element: SVGGElement) => {
                let meetingRoomId = element.getAttribute("id");
                let neighborhoodId = element.getAttribute("neighborhoodId") ?? "";
                if (meetingRoomId !== null && meetingRoomId !== "") {
                    const meetingRoomAndNeighborhoodId: MeetingRoomAndNeighborhood = {
                        meetingRoomId: meetingRoomId,
                        neighborhoodId: neighborhoodId
                    };
                    meetingRoomsAndNeighborhood.push(meetingRoomAndNeighborhoodId);
                }
            })
            return meetingRoomsAndNeighborhood;
        }
    }

    const getHintIdsFromPlan = (fileAsString: string): string[] => {
        if (fileAsString === "") {
            return []
        }
        const roomPlanParser = new DOMParser();
        const roomPlanDoc = roomPlanParser.parseFromString(fileAsString, "image/svg+xml");
        const officeGroup = roomPlanDoc.getElementById("offices")
        if (officeGroup === null) {
            return []
        }

        return getOfficeIds(officeGroup)

        function getOfficeIds(officeGroup: HTMLElement): string[] {
            let hints: string[] = []
            const groupElements = Array.from(officeGroup.getElementsByTagName("rect"));
            groupElements.forEach((element: SVGGElement) => {
                let id = element.getAttribute("id");
                if (id !== null && id !== "") {
                    hints.push(id)
                }
            })
            return hints
        }
    }

    const getMeetingRoomIdsFromPlan = (fileAsString: string): string[] => {
        if (fileAsString === "") {
            return []
        }
        const roomPlanParser = new DOMParser();
        const roomPlanDoc = roomPlanParser.parseFromString(fileAsString, "image/svg+xml");
        const meetingRoomGroup = roomPlanDoc.getElementById("meetingRooms")
        if (meetingRoomGroup === null) {
            return []
        }

        return getMeetingRoomIds(meetingRoomGroup)

        function getMeetingRoomIds(meetingRoomGroup: HTMLElement): string[] {
            let meetingRooms: string[] = []
            const groupElements = Array.from(meetingRoomGroup.getElementsByTagName("g"));
            groupElements.forEach((element: SVGGElement) => {
                let id = element.getAttribute("id");
                if (id !== null && id !== "") {
                    meetingRooms.push(id)
                }
            })
            return meetingRooms
        }
    }

    const getMeetingRoomsFilledFromPlan = (ids: string[], fileAsString: string): MeetingRoom[] => {
        if (fileAsString === "") {
            return []
        }
        const roomPlanParser = new DOMParser();
        const roomPlanDoc = roomPlanParser.parseFromString(fileAsString, "image/svg+xml");
        const meetingRoomGroup = roomPlanDoc.getElementById("meetingRooms")
        if (meetingRoomGroup === null) {
            return []
        }

        return getMeetingRooms(ids, meetingRoomGroup)

        function getMeetingRooms(ids: string[], meetingRoomGroup: HTMLElement): MeetingRoom[] {
            let meetingRooms: MeetingRoom[] = []
            const groupElements = Array.from(meetingRoomGroup.getElementsByTagName("g"));
            groupElements.forEach((element: SVGGElement) => {
                const id = element.getAttribute("id");
                if (id !== null && ids.includes(id)) {
                    const roomCap = element.querySelector(".roomCapacity");
                    const type = element.querySelector(".roomCapacity")?.getAttribute("type") ?? MeetingRoomType.INTERNAL;
                    const capacity = Number(roomCap?.getAttribute("roomCap")) || 0;
                    const neighborhoodId = element.getAttribute("neighborhoodId") ?? "";
                    let meetingRoom: MeetingRoom = {
                        __typename: "MeetingRoom",
                        meetingRoomId: id,
                        roomId: selectedRoom!.roomId,
                        name: id,
                        nameLowerCased: id.toLowerCase(),
                        capacity: capacity,
                        hintId: id,
                        type: type,
                        roleIds: [],
                        neighborhoodId: neighborhoodId,
                        isBookable: false,
                        equipmentIds: [],
                        createdAt: "",
                        updatedAt: ""
                    }
                    meetingRooms.push(meetingRoom)
                }
            })
            return meetingRooms
        }
    }

    const updateRoomPlan = (roomPlanId: string | null | undefined, roomPlanExisting: boolean | null, hasHints: boolean, hasNeigh: boolean, hasMeetRooms: boolean,) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: selectedRoom!.roomId,
                    roomPlanExisting: roomPlanExisting,
                    roomPlanId: roomPlanId,
                    roomPlanScaleFactor: selectedRoom!.roomPlanScaleFactor,
                    hasHints: hasHints,
                    hasNeighborhood: hasNeigh,
                    hasMeetingRooms: hasMeetRooms,
                    orgUnitId: selectedRoom!.orgUnitId,
                    orgUnitAdmin: selectedRoom!.orgUnitAdmin
                },
                updateType: HANDLE_UPDATE_ROOM_PLAN
            }
        }).then(() => setSelectedRoom(undefined)
        ).then(() => setOnDeleteLoading(false)
        ).then(() => setOnUploadLoading(false)
        ).catch((err) => reportError(err, "", "RoomManagerComponent updateRoomPlan"))
    };

    const deleteBookingByBookingIdAndDate = (booking: Booking) => {
        deleteBookingMutation({
            variables: {
                input: {
                    bookingId: booking.bookingId,
                    bookerId: booking.bookerId,
                    orgUnitId: booking.orgUnitId,
                    seatId: booking.seatId,
                    roomId: booking.roomId,
                    date: booking.date
                }
            }
        }).catch((err) => reportError(err, "", "RoomManagerComponent deleteBookingByBookingIdAndDate"))
    }

    const deleteAllBookingsForRoom = async (roomId: string) => {
        let bookingsForRoom: Booking[] = [];
        const result = await refetchBookingsByRoom({input: {roomId: roomId, typeOfQuery: bookingsByRoom}});
        if (result?.data?.getSeatBookings?.items.length > 0) {
            bookingsForRoom = result.data.getSeatBookings.items;
            bookingsForRoom.forEach((booking) => deleteBookingByBookingIdAndDate(booking))
        }
    }

    const deleteSeatConfigBySeatId = (seatId: string) => {
        deleteSeatConfigMutation({
            variables: {
                input: {
                    roomId: selectedRoom!.roomId,
                    seatName: seatId
                }
            }
        }).catch((err) => reportError(err, "", "RoomManagerComponent deleteSeatConfigBySeatId"))
    }

    const deleteBatchSeatConfigBySeatId = (seatId: string, roomPlanUploadActions: RoomPlanUploadActionsInput) => {
        roomPlanUploadActions.delete?.seats?.push({
            roomId: selectedRoom!.roomId,
            seatName: seatId
        });
    }

    function deleteAllNeighborhoodsForRoom() {
        deleteNeighborhoodsByIds(getNeighborHoodIdsFromRoomPlan(currentRoomPlan));
    }

    const deleteAllHintsForRoom = async (roomId: string) => {
        const currentRoomPlanHints = getHintIdsFromPlan(currentRoomPlan)
        currentRoomPlanHints.forEach((hintId) => {
            deleteHintListByHintId(hintId, roomId);
        })
    }

    const deleteAllSeatConfigsForRoom = async () => {
        const currentRoomPlanSeats = getSeatAndNeighborhoodIdsFromPlan(currentRoomPlan)
        currentRoomPlanSeats.forEach((seatAndNeighborhood) => {
            deleteSeatConfigBySeatId(seatAndNeighborhood.seatId);
        })
    }

    const deleteAllMeetingsRoomsForRoom = async (roomId: string) => {
        const currentRoomPlanMeetingsRooms = getMeetingRoomIdsFromPlan(currentRoomPlan)
        currentRoomPlanMeetingsRooms.forEach((meetingsRoomId) => {
            deleteMeetingRoomByRoomId(meetingsRoomId, roomId);
        })
    }

    const deleteMeetingRoomBookingByBookingIdAndDate = (booking: MeetingRoomBooking) => {
        deleteMeetingRoomBookingMutation({
            variables: {
                input: {
                    bookingId: booking.bookingId,
                    bookerId: booking.bookerId,
                    orgUnitId: booking.orgUnitId,
                    meetingRoomId: booking.meetingRoomId,
                    date: booking.date
                }
            }
        }).catch((err) => reportError(err, "", "RoomManagerComponent deleteMeetingRoomBookingByBookingIdAndDate"))
    }

    const deleteAllMeetingRoomBookingsForRoom = async (roomId: string) => {
        let bookingsForRoom: MeetingRoomBooking[] = [];
        const result = await refetchMeetRoomBookingByRoom({roomIds: [roomId]});
        if (result?.data?.getMeetingRoomBookings?.items.length > 0) {
            bookingsForRoom = result.data.getMeetingRoomBookings.items;
            bookingsForRoom.forEach((booking) => deleteMeetingRoomBookingByBookingIdAndDate(booking))
        }
    }
    const deleteNeighborhoodsByIds = (neighborhoodIds: string[]) => {
        let deleteNeighborhoodsInput: DeleteNeighborhoodInput[] = [];
        neighborhoodIds.forEach((id) => {
            deleteNeighborhoodsInput.push({
                roomId: selectedRoom!.roomId,
                neighborhoodId: id,
            })
        })

        if (deleteNeighborhoodsInput?.length > 0) {
            deleteNeighborhoodsMutation({
                variables: {
                    input: {
                        neighborhoods: deleteNeighborhoodsInput
                    }
                }
            }).then().catch((err) => reportError(err, "", "RoomManagerComponent deleteNeighborhoods"))
        }
    }

    const deleteBatchNeighborhoodsByIds = (neighborhoodIds: string[], roomPlanUploadActions: RoomPlanUploadActionsInput) => {
        let deleteNeighborhoodsInput: DeleteNeighborhoodInput[] = [];
        neighborhoodIds.forEach((id) => {
            roomPlanUploadActions.delete?.neighborhoods?.push({
                roomId: selectedRoom!.roomId,
                neighborhoodId: id,
            })
        })
    }

    const deleteHintListByHintId = (hintId: string, roomId?: string) => {
        roomId = roomId ?? selectedRoom!.roomId;
        deleteHintMutation({
            variables: {
                input: {
                    hintId: hintId,
                    roomId: roomId
                }
            },
            context: {headers: {authorization: "Auth " + currentUser.jwt}}
        }).catch((err) => reportError(err, "", "RoomManagerComponent deleteHintListByHintId"))
    }

    const deleteBatchHintListByHintId = (hintId: string, roomId: string, roomPlanUploadActions: RoomPlanUploadActionsInput) => {
        roomPlanUploadActions.delete?.hints?.push({
            hintId: hintId,
            roomId: roomId
        })
    }

    const deleteMeetingRoomByRoomId = (meetingsRoomId: string, roomId?: string) => {
        roomId = roomId ?? selectedRoom!.roomId;
        deleteMeetingRoomMutation({
            variables: {
                input: {
                    roomId: roomId,
                    meetingRoomId: meetingsRoomId
                }
            }
        }).catch((err) => reportError(err, "", "RoomManagerComponent deleteMeetingRoomByRoomId"))
    }

    const deleteBatchMeetingRoomByRoomId = (meetingsRoomId: string, roomId: string, roomPlanUploadActions: RoomPlanUploadActionsInput) => {
        roomPlanUploadActions.delete?.meetingRooms?.push({
            roomId: roomId,
            meetingRoomId: meetingsRoomId
        })
    }

    const onCloseUploadConfirmationDialog = () => {
        setShowUploadConfirmationDialog(false)
        setSeatIdsToDelete([]);
        setHintIdsToDelete([]);
        setNeighborhoodIdsToDelete([]);
    }

    const handleUpdateOrgUnitIdForBookings = (roomId: string, newOrgUnitId: string) => {
        refetchBookingsByRoom({input: {roomId: roomId, typeOfQuery: bookingsByRoom}})
            .then((result) => result?.data?.getSeatBookings?.items?.forEach((booking: Booking) => updateBookingMutation({
                variables: {
                    input: {
                        bookingId: booking.bookingId,
                        date: booking.date,
                        orgUnitId: newOrgUnitId,
                        orgUnitAdmin: "Admin-" + newOrgUnitId,
                    }
                }
            })))
            .then(() => publishRoomAccessChange({variables: {roomId: roomId}}))
            .catch((err) => reportError(err, "", "RoomManagerComponent handleUpdateOrgUnitIdForBookings"))
    }

    const handleDeleteRoom = () => {
        deleteAllBookingsForRoom(selectedRoom!.roomId)
            .then(() => deleteAllHintsForRoom(selectedRoom!.roomId))
            .then(() => deleteAllNeighborhoodsForRoom())
            .then(() => deleteAllSeatConfigsForRoom())
            .then(() => deleteAllMeetingsRoomsForRoom(selectedRoom!.roomId))
            .then(() => deleteAllMeetingRoomBookingsForRoom(selectedRoom!.roomId))
            .then(() => deleteRoomMutation({
                variables: {
                    input: {
                        roomId: selectedRoom!.roomId
                    }
                }
            }))
            .then(() => setIsShowDeleteDialog(false))
            .then(() => setSelectedRoom(undefined))
            .catch(err => reportError(err, "", "RoomManagerComponent handleDeleteRoom"))
    }

    const handleUploadRoomPlan = async (event: ChangeEvent<HTMLInputElement>) => {
        // if there was already an existing SVG, and it was not fetched, abort the upload
        if (!!selectedRoom?.roomPlanId && selectedRoom?.roomPlanId !== "" && currentRoomPlan === "") {
            setIsSVGUploadAbortedDialogOpen(true);
            return;
        }
        let fileString = ""
        if (event.target.files) {
            fileString = await event.target.files[0].text();
        }
        const newRoomPlanNeighs = getNeighborHoodIdsFromRoomPlan(fileString);
        const idsAreValid = areNeighborhoodIdsValid(newRoomPlanNeighs);
        if (!idsAreValid) {
            return;
        }
        if (!event.target.files || !isSvgFileValid(event.target.files[0].name, fileString)) {
            setIsShowAlerts({...initialAlertStates, error: true});
            setTimeout(() => {
                setIsShowAlerts(initialAlertStates)
            }, 4000)
            return;
        }

        setRoomPlanSvgFileToUpload(event.target.files[0])
        const currentRoomPlanSeats = getSeatAndNeighborhoodIdsFromPlan(currentRoomPlan)
        const newRoomPlanSeats = getSeatAndNeighborhoodIdsFromPlan(fileString);
        const [seatsToAdd, seatsToDelete, seatsToUpdate] = getSeatsToAddAndDelete(currentRoomPlanSeats, newRoomPlanSeats)

        const currentRoomPlanHints = getHintIdsFromPlan(currentRoomPlan)
        const newRoomPlanHints = getHintIdsFromPlan(fileString);
        const [hintsToAdd, hintsToDelete] = getElementsToAddAndDelete(currentRoomPlanHints, newRoomPlanHints)

        const currentNeighIds = getNeighborHoodIdsFromRoomPlan(currentRoomPlan);
        const [neighIdsToAdd, neighIdsToDelete] = getElementsToAddAndDelete(currentNeighIds, newRoomPlanNeighs);

        const currentRoomPlanMeetRooms = getMeetingRoomsAndNeighborhoodIdsFromPlan(currentRoomPlan);
        const newRoomPlanMeetRooms = getMeetingRoomsAndNeighborhoodIdsFromPlan(fileString);

        const [meetingRoomsAndNeighborhoodsToAdd, meetingRoomsAndNeighborhoodsToDelete, meetingRoomsAndNeighborhoodsToUpdate] = getMeetingRoomIdsToAddAndDelete(currentRoomPlanMeetRooms, newRoomPlanMeetRooms, selectedRoom!, meetingRoomMap);
        const meetingRoomsToAddIds = meetingRoomsAndNeighborhoodsToAdd.map(meetingRoom => meetingRoom.meetingRoomId);
        const meetingRoomsToDeleteIds = meetingRoomsAndNeighborhoodsToDelete.map(meetingRoom => meetingRoom.meetingRoomId);
        const meetingRoomsToUpdateIds = meetingRoomsAndNeighborhoodsToUpdate.map(meetingRoom => meetingRoom.meetingRoomId);
        const meetRoomsToAdd = getMeetingRoomsFilledFromPlan(meetingRoomsToAddIds, fileString);
        const meetingRoomsToUpdate = getMeetingRoomsFilledFromPlan(meetingRoomsToUpdateIds, fileString);

        const assignedNonExistingNeighborhoods = getAllAssignedNonExistingNeighborhoods(newRoomPlanSeats, newRoomPlanMeetRooms, newRoomPlanNeighs);
        if (assignedNonExistingNeighborhoods.length > 0) {
            setNonExistingNeighborhoodIds(assignedNonExistingNeighborhoods)
            setShowUploadInconsistentDialog(true);
            return;
        }

        setSeatIdsToAdd(seatsToAdd);
        setSeatIdsToDelete(seatsToDelete);
        setSeatsToUpdate(seatsToUpdate);

        setHintIdsToAdd(hintsToAdd);
        setHintIdsToDelete(hintsToDelete);

        setNeighborhoodIdsToAdd(neighIdsToAdd)
        setNeighborhoodIdsToDelete(neighIdsToDelete);

        setNewRoomPlanHasHints(newRoomPlanHints.length > 0);
        setNewRoomPlanHasNeighborhood(newRoomPlanNeighs.length > 0);
        setNewRoomPlanHasMeetingRooms(newRoomPlanMeetRooms.length > 0);

        setMeetingRoomsToAdd(meetRoomsToAdd)
        setMeetingRoomIdsToDelete(meetingRoomsToDeleteIds);
        setMeetingRoomsToUpdate(meetingRoomsToUpdate);

        if (seatsToDelete.length > 0 || seatsToUpdate.length > 0 || meetingRoomsToDeleteIds.length > 0 || meetingRoomsToUpdateIds.length > 0) {
            setShowUploadConfirmationDialog(true);
        } else {
            triggerUploadRoomPlan()
        }
    };

    useEffect(() => {
        async function uploadRoomPlan() {
            // To skip the firing of useEffect on the first load of the component
            if (roomPlanUploadCount > 0) {
                await uploadRoomPlanAfterConfirmation()
            }
        }

        uploadRoomPlan()
            .catch((err) => reportError(err, "", "RoomManagerComponent uploadRoomPlan"))

    }, [roomPlanUploadCount]);

    const uploadRoomPlanAfterConfirmation = async () => {

        const roomPlanId = roomPlanIdGenerator();
        setOnUploadLoading(true)

        let roomPlanUploadActionsInput: RoomPlanUploadActionsInput = {
            create: {
                hints: [],
                neighborhoods: [],
                meetingRooms: [],
                seats: []
            },
            update: {
                hints: [],
                neighborhoods: [],
                meetingRooms: [],
                seats: []
            },
            delete: {
                hints: [],
                neighborhoods: [],
                meetingRooms: [],
                seats: []
            }
        }

        await cleanUpBeforeRoomPlanUpdate(roomPlanUploadActionsInput)

        createNewSeatConfig(seatIdsToAdd, roomPlanUploadActionsInput);
        createNewHintList(hintIdsToAdd, roomPlanUploadActionsInput);
        createNewNeighborhoods(neighborhoodIdsToAdd, roomPlanUploadActionsInput);
        createNewMeetingRoomList(meetingRoomsToAdd, roomPlanUploadActionsInput);

        // update existing meeting rooms and seat configs
        await updateMeetingRooms(meetingRoomsToUpdate, roomPlanUploadActionsInput);
        await updateSeatConfigs(seatsToUpdate, roomPlanUploadActionsInput);

        console.log("roomPlanUploadActionsInput", roomPlanUploadActionsInput)

        const upladResponse = await roomPlanSecureUploadActionsMutation({
            variables: {
                input: roomPlanUploadActionsInput
            }
        }).catch((err) => reportError(err, "", "RoomManagerComponent upladResponse"))

        if (!upladResponse) {
            return
        }

        const preSignedUrlResponse = await refetchPreSignedUrl({
            writeAccess: true,
            roomId: (selectedRoom ? selectedRoom.roomId : ""),
            roomplanId: roomPlanId
        }).catch((err) => reportError(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmationFirst"))

        if (!preSignedUrlResponse || !preSignedUrlResponse.data || !preSignedUrlResponse.data.getPresignedRoomplanUrl) {
            return
        }

        const preSignedUrl = preSignedUrlResponse.data.getPresignedRoomplanUrl
        await fetch(preSignedUrl, {method: 'PUT', body: roomPlanSvgFileToUpload})
            .then(response => {
                validateFileUploadResponseAndUpdateRoomPlan(response, roomPlanId);
                setShowUploadConfirmationDialog(false)
                setSelectedRoom(undefined)
            })
            .catch((err) => reportError(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmation"))

        function createNewSeatConfig(seatsNew: SeatAndNeighborhood[], roomPlanActions: RoomPlanUploadActionsInput) {
            seatsNew.forEach((seat) => {
                roomPlanActions.create?.seats?.push({
                    roomId: selectedRoom!.roomId,
                    seatName: seat.seatId,
                    neighborhoodId: seat.neighborhoodId,
                    seatType: "",
                    inventory: [],
                    owner: "",
                    isBookable: true,
                })
            })
        }

        async function updateSeatConfigs(seats: SeatAndNeighborhood[], roomPlanActions: RoomPlanUploadActionsInput) {

            if (seats.length > 0) {
                try {
                    let seatConfigs: SeatConfig[] = [];

                    //seat configs are required to set the room id in the mutation, as the seats from the svg don't have this property
                    const seatConfigData = await refetchSeatConfigs({roomId: selectedRoom!.roomId}).catch((err) => reportError(err, "", "RoomManagerComponent cleanUpBeforeRoomPlanUpdate - calling refetchMeetRoomBookingByRoom"));
                    if (seatConfigData?.data?.getSeatConfigs?.items?.length > 0) {
                        seatConfigs = seatConfigData?.data?.getSeatConfigs?.items;
                    }

                    seats.map(async seat => {

                        //find seat config that fits to the actual seat
                        const matchingSeatConfig = seatConfigs.find(seatConfig => {
                            return seatConfig.seatName === seat.seatId
                        })

                        roomPlanActions.update?.seats?.push({
                            seatName: seat.seatId,
                            neighborhoodId: seat.neighborhoodId,
                            roomId: matchingSeatConfig?.roomId ?? ""
                        })
                    })

                } catch (error: any) {
                    reportError(error, "", "RoomManagerComponent updateSeatConfigs")
                }
            }
        }


        function createNewHintList(hintsNew: string[], roomPlanActions: RoomPlanUploadActionsInput) {
            hintsNew.forEach((el) => {
                roomPlanActions.create?.hints?.push({
                    hintId: el,
                    roomId: selectedRoom!.roomId,
                    text: "",
                })
            })
        }

        function createNewNeighborhoods(neighborhoodIds: string[], roomPlanActions: RoomPlanUploadActionsInput) {
            refetchNeighborhoods({roomId: selectedRoom!.roomId})
                .then((result) => {
                    const neighborhoodIdsDb: string[] = result?.data?.getNeighborhoodsByRoomId?.items?.map((n: Neighborhood) => n.neighborhoodId) ?? [];
                    // filter out those already existing in the database, and only create new neighborhoods:
                    const newNeighborhoodIdsToCreate = neighborhoodIds.filter(n => !neighborhoodIdsDb.includes(n));
                    createNewNeighborhoodsForIds(newNeighborhoodIdsToCreate, roomPlanActions);
                }).catch((err) => reportError(err, "", "RoomManagerComponent createNewNeighborhoods"));
        }

        function createNewNeighborhoodsForIds(neighborhoodIds: string[], roomPlanActions: RoomPlanUploadActionsInput) {
            neighborhoodIds.forEach((id) => {
                roomPlanActions.create?.neighborhoods?.push({
                    roomId: selectedRoom!.roomId,
                    neighborhoodId: id,
                    restricted: false,
                    roleIds: [],
                    name: reformatNeighborhoodId(id),
                    adminRoleIds: []
                })
            })
        }

        function createNewMeetingRoomList(meetingRoomsNew: MeetingRoom[], roomPlanActions: RoomPlanUploadActionsInput) {
            if (meetingRoomsNew.length > 0) {
                meetingRoomsNew.map(el => ({
                    meetingRoomId: el.meetingRoomId,
                    roomId: el.roomId,
                    name: el.name,
                    nameLowerCased: el.name.toLowerCase(),
                    hintId: el.hintId,
                    capacity: el.capacity,
                    type: el.type,
                    roleIds: el.roleIds,
                    neighborhoodId: el.neighborhoodId,
                    equipmentIds: el.equipmentIds ?? [],
                    isBookable: el.isBookable
                })).forEach((el) => {
                    roomPlanActions.create?.meetingRooms?.push(el)
                });
            }
        }

        async function updateMeetingRooms(meetingRooms: MeetingRoom[], roomPlanUploadActions: RoomPlanUploadActionsInput) {
            if (meetingRooms.length > 0) {
                try {
                    let existingMeetingRooms: MeetingRoom[] = [];
                    const meetingRoomsData = await refetchMeetingRooms({roomIds: [selectedRoom!.roomId]}).catch((err) => reportError(err, "", "RoomManagerComponent updateMeetingRooms - calling refetchMeetingRooms"));

                    if (meetingRoomsData?.data?.getMeetingRooms?.items?.length > 0) {
                        existingMeetingRooms = meetingRoomsData?.data?.getMeetingRooms?.items;
                    }
                    meetingRooms.map(async meetingRoom => {
                        const matchingMeetingRoom = existingMeetingRooms.find(possibleMeetingRoom => possibleMeetingRoom.meetingRoomId === meetingRoom.meetingRoomId)
                        roomPlanUploadActions.update?.meetingRooms?.push({
                            meetingRoomId: meetingRoom.meetingRoomId,
                            roomId: meetingRoom.roomId,
                            name: matchingMeetingRoom!.name,
                            nameLowerCased: matchingMeetingRoom!.name.toLowerCase(),
                            hintId: matchingMeetingRoom!.hintId,
                            capacity: matchingMeetingRoom!.capacity,
                            type: matchingMeetingRoom!.type,
                            roleIds: matchingMeetingRoom!.roleIds,
                            neighborhoodId: meetingRoom.neighborhoodId,
                            equipmentIds: meetingRoom.equipmentIds ?? [],
                            isBookable: matchingMeetingRoom!.isBookable
                        })
                    })

                } catch (error: any) {
                    reportError(error, "", "RoomManagerComponent updateMeetingRooms")
                }
            }
        }


        function roomPlanIdGenerator() {
            return Math.random().toString(36).substring(2, 10);
        }

        async function cleanUpBeforeRoomPlanUpdate(roomPlanUploadActionsInput: RoomPlanUploadActionsInput) {

            let bookingsForRoom: Booking[] = [];
            let meetingRoomBookingsForRoom: MeetingRoomBooking[] = [];

            const result = await refetchBookingsByRoom({
                input: {
                    roomId: selectedRoom!.roomId,
                    typeOfQuery: bookingsByRoom
                }
            })
                .catch((err) => reportError(err, "", "RoomManagerComponent cleanUpBeforeRoomPlanUpdate"));
            if (result?.data?.getSeatBookings?.items?.length > 0) {
                bookingsForRoom = result?.data.getSeatBookings.items;
            }

            const resultMeetRoomBookings = await refetchMeetRoomBookingByRoom({roomIds: [selectedRoom!.roomId]})
                .catch((err) => reportError(err, "", "RoomManagerComponent cleanUpBeforeRoomPlanUpdate - calling refetchMeetRoomBookingByRoom"));
            if (resultMeetRoomBookings?.data?.getMeetingRoomBookings?.items?.length > 0) {
                meetingRoomBookingsForRoom = resultMeetRoomBookings?.data?.getMeetingRoomBookings?.items;
            }

            deleteSeatsFromDatabase(bookingsForRoom, roomPlanUploadActionsInput);
            deleteHintsFromDatabase(roomPlanUploadActionsInput);
            deleteNeighsFromDatabase(roomPlanUploadActionsInput);
            deleteMeetingRoomsFromDatabase(meetingRoomBookingsForRoom, roomPlanUploadActionsInput);

            function deleteSeatsFromDatabase(bookingsForRoom: Booking[], roomPlanActions: RoomPlanUploadActionsInput) {
                seatIdsToDelete.forEach((seat) => {
                    deleteBookingsForSeat(bookingsForRoom, seat.seatId);
                    deleteBatchSeatConfigBySeatId(seat.seatId, roomPlanActions);
                });

                function deleteBookingsForSeat(bookingsForRoom: Booking[], seatId: string) {
                    bookingsForRoom.forEach((booking) => {
                        if (booking.seatId === seatId) {
                            deleteBookingByBookingIdAndDate(booking)
                        }
                    })
                }
            }

            function deleteHintsFromDatabase(roomPlanUploadActions: RoomPlanUploadActionsInput) {
                hintIdsToDelete.forEach((hintId) => {
                    deleteBatchHintListByHintId(hintId, selectedRoom!.roomId, roomPlanUploadActions);
                });
            }

            function deleteNeighsFromDatabase(roomPlanUploadActions: RoomPlanUploadActionsInput) {
                deleteBatchNeighborhoodsByIds(neighborhoodIdsToDelete, roomPlanUploadActions);
            }

            function deleteMeetingRoomsFromDatabase(roomMeetingBookingsForRoom: MeetingRoomBooking[], roomPlanUploadActions: RoomPlanUploadActionsInput) {
                meetingRoomIdsToDelete.forEach((meetingRoomId) => {
                    deleteMeetingRoomBookingsForMeetingRoom(roomMeetingBookingsForRoom, meetingRoomId);
                    deleteBatchMeetingRoomByRoomId(meetingRoomId, selectedRoom!.roomId, roomPlanUploadActions);
                });

                function deleteMeetingRoomBookingsForMeetingRoom(roomMeetingBookingsForRoom: MeetingRoomBooking[], meetingRoomId: string) {
                    roomMeetingBookingsForRoom.forEach((meetingRoom) => {
                        if (meetingRoom.meetingRoomId === meetingRoomId) {
                            deleteMeetingRoomBookingByBookingIdAndDate(meetingRoom)
                        }
                    })
                }
            }
        }

        function validateFileUploadResponseAndUpdateRoomPlan(response: Response, roomPlanId: string) {
            if (response.status === 200) {
                updateRoomPlan(roomPlanId, true, newRoomPlanHasHints, newRoomPlanHasNeighborhood, newRoomPlanHasMeetingRooms)
                setIsShowAlerts({...initialAlertStates, success: true})
                setTimeout(() => {
                    setIsShowAlerts(initialAlertStates)
                }, 4000)
            } else {
                console.error("roomplan " + roomPlanId + " for room " + selectedRoom!.roomId + " not uploaded")
                reportError(new Error(), "", "RoomManagerComponent validateFileUploadResponseAndUpdateRoomPlan")
            }
        }

        function tmpValidate() {
            setIsShowAlerts({...initialAlertStates, success: true})
            setTimeout(() => {
                setIsShowAlerts(initialAlertStates)
            }, 4000)
            setOnUploadLoading(false)
        }

    }


    const updateTimeDataTo00h00_23h59 = useCallback((bookings: Booking[]) => {
        bookings.forEach((booking: Booking) => {
            updateBookingMutation({
                variables: {
                    input: {
                        bookingId: booking.bookingId,
                        roomId: booking.roomId,
                        date: booking.date,
                        timeBegin: dayjs(booking.date).hour(0).minute(0).utc().toISOString(),
                        timeEnd: dayjs(booking.date).hour(23).minute(59).utc().toISOString()
                    },
                }
            })
                .catch(e => reportError(e, "", "RoomManagerComponent updateTimeDataTo00h00_23h59"))

        })
    }, []);

    const handleDisableTimeBooking = useCallback((room: Room, timeActive: boolean) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: room?.roomId,
                    isTimeActive: false,
                    orgUnitId: room!.orgUnitId,
                    orgUnitAdmin: room!.orgUnitAdmin
                },
                updateType: HANDLE_UPDATE_ROOM_IS_TIME_ACTIVE
            }
        })
            .then(() => publishRoomAccessChange({variables: {roomId: room?.roomId}}).then())
            .then(() => setIsShowingDisableTimeBookingConfirmationDialog(false))
            .catch((err) => reportError(err, "", "RoomManagerComponent handleDisableTimeBooking"))

        if (timeActive) handleChangeOfExistingTimeBookings(room.roomId)

        function handleChangeOfExistingTimeBookings(roomId: string) {
            getBookingsWhenTimeIsActive({input: {roomId: roomId, typeOfQuery: bookingsByRoom}})
                .then((res: any) => updateTimeDataTo00h00_23h59(res.data.getSeatBookings.items!))
                .then(() => publishRoomAccessChange({variables: {roomId: roomId}}))
                .catch((err) => reportError(err, "", "RoomManagerComponent"))
        }

    }, [updateRoomMutation, publishRoomAccessChange, reportError, getBookingsWhenTimeIsActive, updateTimeDataTo00h00_23h59])

    const fillMultipleBookingsPerSeatList = useCallback((roomId: string) => {
        let listOfMultipleBookingsOnSeat: Booking[] = [];
        getBookingsOfRoomPlan({input: {roomId: roomId, typeOfQuery: bookingsByRoom}})
            .then((result) => collectMultipleBookingsOnSeat(result.data.getSeatBookings.items))
            .catch((err) => reportError(err, "", "RoomManagerComponent fillMultipleBookingsPerSeatList"))
            .finally(handleFinally)

        function collectMultipleBookingsOnSeat(bookings: Booking[]) {
            let uniqueCombination: string[] = []
            let sortedBookings = makeArraySortedByBeginTime(bookings)

            sortedBookings.forEach((booking: Booking) => {

                const bookingCode = getBookingCode(booking);
                const isAnotherBookingExistsOnSeatOnTheSameDate = uniqueCombination.includes(bookingCode);

                (isAnotherBookingExistsOnSeatOnTheSameDate) ? listOfMultipleBookingsOnSeat.push(booking) : uniqueCombination.push(bookingCode)

            })

            function getBookingCode(booking: Booking): string {
                return booking.roomId + "_" + booking.seatId + "_" + booking.date
            }

            function makeArraySortedByBeginTime(unsortedBookingList: Booking[]): Booking[] {
                const arrayToSort = [...unsortedBookingList]; // can't sort the param directly because it's a readonly value
                return arrayToSort.sort((leftHand, rightHand) => {
                    const leftHandValue: number = leftHand.timeBegin == null ? Number.MAX_SAFE_INTEGER : Date.parse(leftHand.timeBegin)
                    const rightHandValue: number = rightHand.timeBegin == null ? Number.MAX_SAFE_INTEGER : Date.parse(rightHand.timeBegin)
                    return leftHandValue - rightHandValue
                })
            }
        }

        function handleFinally() {
            if (!isLoadingBookingsToDelete) return;

            const isThereMultipleBookingOnSeats = listOfMultipleBookingsOnSeat.length !== 0;
            if (isThereMultipleBookingOnSeats) {
                setIsShowingDisableTimeBookingConfirmationDialog(true)
            } else {
                handleDisableTimeBooking(roomToBeUpdatedAccordingToTimeFeature as Room, true)
                disableRoomWhileTransitioning(roomToBeUpdatedAccordingToTimeFeature as Room, false)
            }
            setIsLoadingBookingsToDelete(false)
            setMultipleBookingsPerSeat(listOfMultipleBookingsOnSeat)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getBookingsOfRoomPlan, handleDisableTimeBooking, isLoadingBookingsToDelete, roomToBeUpdatedAccordingToTimeFeature, updateTimeDataTo00h00_23h59])

    useEffect(function fetchMultipleBookingsPerSeat() {
        if (isLoadingBookingsToDelete) fillMultipleBookingsPerSeatList(roomToBeUpdatedAccordingToTimeFeature?.roomId ?? "");
    }, [fillMultipleBookingsPerSeatList, isLoadingBookingsToDelete, roomToBeUpdatedAccordingToTimeFeature]);

    function getTimeBookingDialogText(): string {
        let bookingsCount = multipleBookingsPerSeat.length;
        let errorMsg = "";
        if (bookingsCount === 1) {
            errorMsg += (t("message_disable_time_booking"))
        } else {
            errorMsg += (t("message_disable_time_bookings")).replace("%number", String(bookingsCount))
        }
        return errorMsg
    }

    const handleIsTimeBox = (room: Room, didTimeBecomeActive: boolean) => {
        setRoomToBeUpdatedAccordingToTimeFeature(room)

        if (didTimeBecomeActive) {
            updateRoomMutation({
                variables: {
                    input: {
                        roomId: room.roomId,
                        isTimeActive: true,
                        orgUnitId: room!.orgUnitId,
                        orgUnitAdmin: room!.orgUnitAdmin
                    },
                    updateType: HANDLE_UPDATE_ROOM_IS_TIME_ACTIVE
                }
            }).catch((err) => reportError(err, "", "RoomManagerComponent, handleIsTimeBox"));
        } else {
            // this will fire a useEffect (fetchMultipleBookingsPerSeat) to check if there are multiple bookings on a seats
            // and this might lead to showing a confirmation dialog through (isShowingDisableTimeBookingConfirmationDialog)
            // since we needed a comment to explain the process, then it needs to be refactored
            setIsLoadingBookingsToDelete(true)
        }

        if (!room.isTimeActive) disableRoomWhileTransitioning(room, false)
    }

    const disableRoomWhileTransitioning = (room: Room, disableBooking: boolean) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: room.roomId,
                    isBookingDisabled: disableBooking,
                    orgUnitId: room!.orgUnitId,
                    orgUnitAdmin: room!.orgUnitAdmin
                },
                updateType: HANDLEE_UPDATE_ROOM_IS_BOOKING_DISABLED
            }
        })
            .then(() => publishRoomAccessChange({variables: {roomId: room.roomId}}))
            .catch((err) => reportError(err, "", "RoomManagerComponent, disableRoomWhileTransitioning"))
    }

    const handleOkButtonOfTimeBookingDialog = () => {
        handeDeleteMultipleBookingsPerSeat();
        setTimeout(() => handleDisableTimeBooking(roomToBeUpdatedAccordingToTimeFeature as Room, true), 500)
        disableRoomWhileTransitioning(roomToBeUpdatedAccordingToTimeFeature as Room, false)

        function handeDeleteMultipleBookingsPerSeat() {
            multipleBookingsPerSeat.forEach((booking) => {
                deleteBookingMutation({
                    variables: {
                        input: {
                            bookingId: booking.bookingId,
                            bookerId: booking.bookerId,
                            orgUnitId: booking.orgUnitId,
                            seatId: booking.seatId,
                            roomId: booking.roomId,
                            date: booking.date
                        }
                    }
                })
                    .then(() => removeDoubledBookingFromList(booking.bookingId))
                    .catch((err) => reportError(err, "", "RoomManagerComponent handeDeleteMultipleBookingsPerSeat"))
            })

            function removeDoubledBookingFromList(bookingId: string) {
                setMultipleBookingsPerSeat((prev) => prev.filter(booking => booking.bookingId !== bookingId));
            }
        }
    }

    const handleCancelButtonOfTimeBookingDialog = () => {
        setIsShowingDisableTimeBookingConfirmationDialog(false)
        disableRoomWhileTransitioning(roomToBeUpdatedAccordingToTimeFeature as Room, false)
    }

    const handleDeleteRoomPlan = async () => {
        setOnDeleteLoading(true);

        const currentRoomPlanHints = getHintIdsFromPlan(currentRoomPlan)
        const currentRoomPlanNeighs = getNeighborHoodIdsFromRoomPlan(currentRoomPlan)
        const currentRoomPlanMeetingRooms = getMeetingRoomIdsFromPlan(currentRoomPlan)

        updateRoomPlan(selectedRoom!.roomPlanId, false, currentRoomPlanHints.length !== 0,
            currentRoomPlanNeighs.length !== 0, currentRoomPlanMeetingRooms.length !== 0)
    };

    const handleCreateRoom = () => {
        setSelectedRoom(undefined)
        setShowCreateRoom(true)
    }

    function getIndex(selectedBuilding: Building) {
        if (selectedBuilding === undefined) return 0;
        return allowedBuildings.findIndex(building => building.buildingId === selectedBuilding.buildingId);
    }

    function getBuildingOptions(): SelectItem<Building>[] {
        const displayName: (item: Building) => string = (item: Building) => {
            return item.buildingName;
        };
        return allowedBuildings.map(building => {
            return {element: building, label: displayName(building)};
        });

    }

    const {t} = useTranslation();
    return (
        <Dialog fullWidth={true} maxWidth={"lg"} style={{width: "100%", flexGrow: 1}} open={showRoomManager}
                data-testid={"roomManager"}>
            {isShowAlerts.error && <Alert severity="error">{t('message_error_file_type')}</Alert>}
            {isShowAlerts.success && <Alert severity="success">{t('message_success_uploaded_file')}</Alert>}
            {isShowAlerts.error_create_room && <Alert severity="error">{t('message_error_create_room')} </Alert>}
            <DialogTitle>{t("room_management_dialog-title")}</DialogTitle>
            <InfoDialog
                open={isNeighborhoodIdMaxLengthDialogOpen}
                onClose={() => setIsNeighborhoodIdMaxLengthDialogOpen(false)}
                title={t('neighborhood_id_too_long_title')}
                content={t('neighborhood_id_too_long_text') + neighborhoodWithTooLongId}
            />
            <InfoDialog
                open={isSVGUploadAbortedDialogOpen}
                onClose={() => setIsSVGUploadAbortedDialogOpen(false)}
                title={t('svg_upload_aborted_title')}
                content={t('svg_upload_aborted_text')}
            />
            <ClickAwayListener onClickAway={() => {
                if (!showSeatManagerDialog && !showMeetingRoomManagerDialog) {
                    setSelectedRoom(undefined)
                }
            }}>
                <Box>
                    <DialogContent>
                        {currentUser.isAdmin || allowedBuildings.length > 0 || permissionHelper.hasAnyNeighborhoodManagement() ?
                            <GenericSelectorComponent disabled={allowedBuildings.length === 1}
                                                      labelText={t("room_plan_manage_building")}
                                                      chooseFrom={getBuildingOptions()}
                                                      selectedIndex={getIndex(selectedBuilding)}
                                                      setSelected={setSelectedBuilding}
                                                      style={{width: "100%", textAlign: "left"}}
                                                      isOutlined={true}
                                                      dataTestId="select-building"
                            />
                            :
                            <div data-testid={"org-unit-label"}>{t("daily_bookings_table_orgunit_column")}
                                <div
                                    data-testid={"org-unit-label-content"}>{allowedBuildings[0] && allowedBuildings[0].buildingName}</div>
                            </div>
                        }

                        {showCreateRoom &&
                            <CreateRoomComponent
                                selectedBuilding={selectedBuilding}
                                setIsShowAlerts={setIsShowAlerts}
                                roomToEdit={selectedRoom}
                                showCreateRoom={showCreateRoom}
                                setShowCreateRoom={setShowCreateRoom}
                                isAdmin={currentUser.isAdmin || currentUser.isOrgUnitAdmin}
                                handleUpdateOrgUnitIdForBookings={handleUpdateOrgUnitIdForBookings}
                            />}
                        {isShowDeleteDialog && <Dialog open={isShowDeleteDialog}>
                            <DialogContent>
                                {t("delete_room_dialog_content")}
                                <DialogActions>
                                    <Button variant={"contained"} color={"primary"} component={"span"}
                                            onClick={handleDeleteRoom}>
                                        {t("confirm_dialog_ok_button-text")}
                                    </Button>
                                    <Button variant={"contained"} color={"primary"} component={"span"}
                                            onClick={() => setIsShowDeleteDialog(false)}>
                                        {t("confirm_dialog_cancel_button-text")}
                                    </Button>
                                </DialogActions>
                            </DialogContent>
                        </Dialog>}

                        {isShowingDisableTimeBookingConfirmationDialog &&
                            <Dialog open={isShowingDisableTimeBookingConfirmationDialog}>
                                <DialogContent data-testid={"dialogContent-changeIsTimed-test"}>
                                    <DialogContentText data-testid={"dialogContentText"}>
                                        {getTimeBookingDialogText()}
                                    </DialogContentText>
                                    <DialogActions>
                                        <Button variant={"contained"} color={"primary"} component={"span"}
                                                onClick={handleOkButtonOfTimeBookingDialog}>
                                            {t("confirm_dialog_ok_button-text")}
                                        </Button>
                                        <Button variant={"contained"} color={"primary"} component={"span"}
                                                onClick={handleCancelButtonOfTimeBookingDialog}>
                                            {t("confirm_dialog_cancel_button-text")}
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>}

                        {selectedRoom &&
                            <SeatManagerComponent room={selectedRoom}
                                                  showSeatManager={showSeatManagerDialog}
                                                  setShowSeatManager={setShowSeatManagerDialog}
                            />
                        }
                        {selectedRoom &&
                            <RoomHintsManagerComponent room={selectedRoom}
                                                       showRoomHintsConfig={showRoomHintsConfig}
                                                       setShowRoomHintsConfig={setShowRoomHintsConfig}
                                                       cognitoUserList={cognitoUserList.userList}
                                                       building={selectedBuilding}
                            />
                        }
                        {selectedRoom && showNeighborhoodManagerDialog &&
                            <NeighborhoodManagerComponent
                                showNeighborhoodManager={showNeighborhoodManagerDialog}
                                setShowNeighborhoodManager={setShowNeighborhoodDialog}
                                selectedRoom={selectedRoom}
                            />
                        }
                        {selectedRoom &&
                            <MeetingRoomManagerComponent
                                selectedRoom={selectedRoom}
                                showMeetingRoomManager={showMeetingRoomManagerDialog}
                                setShowMeetingRoomManager={setShowMeetingRoomManagerDialog}
                            />
                        }
                        <RoomManagerTableComponent
                            rooms={rooms}
                            selectedRoom={selectedRoom}
                            selectedBuilding={selectedBuilding}
                            setSelectedRoom={setSelectedRoom}
                            setShowSeatConfig={setShowSeatManagerDialog}
                            setShowRoomHintsConfig={setShowRoomHintsConfig}
                            setShowNeighborhoodConfig={setShowNeighborhoodDialog}
                            setShowMeetingRoomsConfig={setShowMeetingRoomManagerDialog}
                            handleIsTimeBox={handleIsTimeBox}
                        />
                    </DialogContent>
                    <Divider/>
                    {permissionHelper.hasManagementForBuilding(selectedBuilding) &&
                        <DialogActions style={{display: "flex", justifyContent: "center", alignItems: "stretch"}}>
                            <Button
                                variant={"contained"} color={"primary"} startIcon={<Sit2GetherAddIcon/>}
                                onClick={handleCreateRoom}
                                data-testid={"create-btn"}
                            >
                                {t("button_create_room-text")}
                            </Button>
                            <RoomManagerUploadSvgButton handleUploadRoomPlan={handleUploadRoomPlan}
                                                        selectedRoom={selectedRoom} uploadStartIcon={uploadStartIcon}/>
                            <Button variant={"contained"} color={"primary"} startIcon={deleteStartIcon}
                                    style={{wordBreak: "break-word"}}
                                    disabled={!selectedRoom?.roomPlanExisting}
                                    onClick={handleDeleteRoomPlan}
                                    data-testid={"btn-delete"}
                            >
                                {t("button_svg_delete-text")}
                            </Button>
                            <Button variant={"contained"} color={"primary"} startIcon={<Edit/>}
                                    style={{wordBreak: "break-word"}}
                                    disabled={!selectedRoom}
                                    onClick={() => setShowCreateRoom(true)}
                                    data-testid={"btn-edit-room"}
                            >
                                {t("inv_mngmnt_edit_button-text")}
                            </Button>
                            <Button variant={"contained"} color={"primary"} startIcon={<DeleteForeverIcon/>}
                                    style={{wordBreak: "break-word"}}
                                    disabled={!selectedRoom}
                                    onClick={() => setIsShowDeleteDialog(true)}
                                    data-testid={"btn-delete-room"}
                            >
                                {t("delete")}
                            </Button>
                        </DialogActions>}
                    <Divider/>

                    <DialogActions>
                        <Button
                            onClick={() => setShowRoomManager(false)}
                            color={"primary"}
                            variant={"contained"}
                            data-testid={"close-btn"}
                        >
                            {t("button_close_room_management_dialog-text")}
                        </Button>
                    </DialogActions>
                </Box>
            </ClickAwayListener>
            <RoomManagerUploadInconsistentDialog
                showDialog={showUploadInconsistentDialog}
                nonExistedNeighborhoodIds={nonExistingNeighborhoodIds}
                onCloseDialog={() => {setShowUploadInconsistentDialog(false)}}>
            </RoomManagerUploadInconsistentDialog>
            <RoomManagerUploadConfirmationDialog
                showUploadConfirmationDialog={showUploadConfirmationDialog}
                onCloseUploadConfirmationDialog={onCloseUploadConfirmationDialog}
                onUploadLoading={onUploadLoading}
                seatIdsToDelete={seatIdsToDelete}
                seatsToUpdate={seatsToUpdate}
                meetingRoomIdsToDelete={meetingRoomIdsToDelete}
                meetingRoomsToUpdate={meetingRoomsToUpdate}
                triggerUploadRoomPlan={triggerUploadRoomPlan}
            ></RoomManagerUploadConfirmationDialog>
        </Dialog>
    )
}
export default RoomManagerComponent;

